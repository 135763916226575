const contactFabButtonEl = document.querySelector(".contact-fab__button");
const contactFabEl = document.querySelector(".contact-fab");

const toggleContactFab = e => {
  contactFabEl.classList.toggle("contact-fab--active");
};

contactFabButtonEl.addEventListener("click", toggleContactFab);

// Gallerypreview

const galleryPreviewEl = document.querySelector(".gallery-preview");
if (galleryPreviewEl) {
  const galleryPreviewSlideshowEl = document.querySelector(
    ".gallery-preview__slideshow"
  );
  const galleryPreviewNextEl = document.querySelector(".gallery-preview__next");
  const galleryPreviewPrevEl = document.querySelector(".gallery-preview__prev");
  const galleryPreviewItemEls = document.querySelectorAll(
    ".gallery-preview__item-link"
  );

  let scrollPosition = 0;
  let atStart = false;
  let atEnd = false;

  const updateScrollPosition = () => {
    galleryPreviewItemElsArray = Array.prototype.slice.call(
      galleryPreviewItemEls
    );
    const currentPosition = galleryPreviewItemElsArray.findIndex(
      (galleryPreviewItemEl, index) =>
        galleryPreviewItemEl.getBoundingClientRect().x +
          galleryPreviewItemEl.getBoundingClientRect().width +
          galleryPreviewSlideshowEl.scrollLeft -
          galleryPreviewSlideshowEl.scrollLeft >=
        0
    );
    atStart = galleryPreviewSlideshowEl.scrollLeft === 0;
    atEnd =
      galleryPreviewSlideshowEl.getBoundingClientRect().width +
        galleryPreviewSlideshowEl.scrollLeft ===
      galleryPreviewSlideshowEl.scrollWidth;
    console.log(
      galleryPreviewSlideshowEl.getBoundingClientRect().width +
        galleryPreviewSlideshowEl.scrollLeft ===
        galleryPreviewSlideshowEl.scrollWidth
    );
    if (atStart) {
      galleryPreviewPrevEl.classList.add("gallery-preview__prev--inactive");
    } else {
      galleryPreviewPrevEl.classList.remove("gallery-preview__prev--inactive");
    }
    if (atEnd) {
      galleryPreviewNextEl.classList.add("gallery-preview__next--inactive");
    } else {
      galleryPreviewNextEl.classList.remove("gallery-preview__next--inactive");
    }
    scrollPosition = currentPosition;
  };

  const scrollTo = positionUpdate => {
    const nextItem = galleryPreviewItemEls[scrollPosition + positionUpdate];
    if (nextItem) {
      const targetPosition =
        nextItem.getBoundingClientRect().x +
        galleryPreviewSlideshowEl.scrollLeft;
      console.log(targetPosition);
      galleryPreviewSlideshowEl.scrollTo({
        left: targetPosition,
        behavior: "smooth"
      });
    }
  };

  const scrollToNext = () => scrollTo(1);
  const scrollToPrev = () => scrollTo(-1);

  galleryPreviewNextEl.addEventListener("click", scrollToNext);
  galleryPreviewPrevEl.addEventListener("click", scrollToPrev);

  updateScrollPosition();

  galleryPreviewSlideshowEl.addEventListener("scroll", updateScrollPosition);
}

// Nav
const menuButtonEl = document.querySelector(".header__menu-button");
const navEl = document.querySelector(".header__nav");

if (menuButtonEl) {
  menuButtonEl.addEventListener("click", () => {
    navEl.classList.toggle("header__nav--active");
  });
}

// Close Clicks
window.addEventListener("click", () => {
  if (contactFabEl) {
    contactFabEl.classList.remove("contact-fab--active");
  }
  if (navEl) {
    navEl.classList.remove("header__nav--active");
  }
});

if (contactFabEl) {
  contactFabEl.addEventListener("click", e => {
    e.stopPropagation();
  });
}

if (navEl) {
  navEl.addEventListener("click", e => {
    e.stopPropagation();
  });
}

if (menuButtonEl) {
  menuButtonEl.addEventListener("click", e => {
    e.stopPropagation();
  });
}
